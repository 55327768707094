// localStorage.js
import {checkEnv} from "@/utils/common";

const prefix_env = checkEnv() + '_'
export default {
  //********普通数据持久化*********
  // 存储数据
  set(key, value) {
    if (typeof value === 'object') {
      // 如果值是对象或数组，先将其转换为 JSON 字符串再存储
      value = JSON.stringify(value);
    }
    localStorage.setItem(`${prefix_env}` + key, value);
  },

  // 获取数据
  get(key) {
    const value = localStorage.getItem(`${prefix_env}` + key,);
    try {
      // 尝试解析 JSON 字符串
      return JSON.parse(value);
    } catch (error) {
      // 如果解析失败，则返回原始值
      return value;
    }
  },

  // 删除指定的数据
  remove(key) {
    localStorage.removeItem(`${prefix_env}` + key,);
  },

  // 清空所有
  clear() {
    const regex = new RegExp(`^${prefix_env}`);
    const keysToRemove = [];

    for (let i = 0; i < localStorage.length; i++) {
      const storageKey = localStorage.key(i);
      if (regex.test(storageKey)) {
        keysToRemove.push(storageKey);
      }
    }

    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
  },

  //************系统数据持久化**************
  // 存储数据
  sysSet(value) {
    let storedData = localStorage.getItem(`${prefix_env}sys`);

    if (storedData) {
      // 如果本地存储中已有数据，则解析为对象或数组
      storedData = JSON.parse(storedData);

      if (Array.isArray(storedData)) {
        // 如果是数组，则将新值添加到数组末尾
        storedData.push(value);
      } else if (typeof storedData === 'object') {
        // 如果是对象，则将新值合并到对象中
        storedData = {...storedData, ...value};
      }
    } else {
      // 如果本地存储中没有数据，则初始化为新值
      storedData = value;
    }

    // 将存储数据转换为 JSON 字符串并存储到本地
    localStorage.setItem(`${prefix_env}sys`, JSON.stringify(storedData));
  },

  // 获取数据
  sysGet() {
    const value = localStorage.getItem(`${prefix_env}sys`);
    try {
      // 尝试解析 JSON 字符串
      return JSON.parse(value);
    } catch (error) {
      // 如果解析失败，则返回原始值
      return value;
    }
  },

  // 删除数据
  sysRemove() {
    localStorage.removeItem(`${prefix_env}sys`);
  },

  // 存储数据并设置过期时间
  setWithExpiry(key, value, ttl) {
    const now = new Date();

    // 封装数据和过期时间
    const item = {
      value: value,
      expiry: now.getTime() + ttl, // 当前时间加上有效期（毫秒）
    };
    localStorage.setItem(`${prefix_env}` + key, JSON.stringify(item));

    // 使用----设置数据，过期时间为1小时
    // setWithExpiry("cachedData", "exampleValue", 60 * 60 * 1000);
  },

  // 获取缓存数据
  getWithExpiry(key) {
    const itemStr = localStorage.getItem(`${prefix_env}` + key);
    if (!itemStr) {
      return null; // 如果没有数据
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // 检查是否已过期
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(`${prefix_env}` + key); // 删除已过期数据
      return null;
    }
    return item.value; // 返回存储值
  },
};

